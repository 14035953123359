import { take, call, put } from "redux-saga/effects";
import {
  createApplication,
  createApplicationSuccess,
  deleteApplication,
  deleteApplicationSuccess,
  getApplicationStats,
  getApplicationStatsFailure,
  getApplicationStatsSuccess,
  getApplicationTimeline,
  getApplicationTimelineFailure,
  getApplicationTimelineSuccess,
  getCryptoProperties,
  getCryptoPropertiesFailure,
  getCryptoPropertiesSuccess,
  retrieveApplication,
  retrieveApplications,
  retrieveApplicationsFailure,
  retrieveApplicationsSuccess,
  retrieveApplicationSuccess,
  searchApplication,
  searchApplicationSuccess,
  updateApplication,
  updateApplicationSuccess,
  updateApplicationAlgoMap,
  updateApplicationAlgoMapSuccess,
} from "../actions/Application";

import {
  CREATE_APPLICATION_PATH,
  UPDATE_APPLICATION_PATH,
  DELETE_APPLICATION_PATH,
  GET_APPLICATION_STATS_PATH,
  GET_APPLICATION_TIMELINE_PATH,
  RETRIEVE_APPLICATIONS_PATH,
  RETRIEVE_APPLICATION_PATH,
  SEARCH_APPLICATION_PATH,
  CRYPTO_PROPERTIES_PATH,
  CRYPTO_PROPERTY,
  APPLICATION_ALGO_CHANGE_PATH,
} from "../constants/Application";

import { endSession } from "../../utils/commonUtils";
import ApiInvoke from "utils/apiFetch";

const backendEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_APPLICATION_BACKEND_DEV}/${path}`
    : process.env.REACT_APP_ENV === "qa"
    ? `${process.env.REACT_APP_APPLICATION_BACKEND_QA}/${path}`
    : `${process.env.REACT_APP_APPLICATION_BACKEND_SANDBOX}/${path}`;

export function* retrieveApplicationsAPI() {
  while (true) {
    yield take(`${retrieveApplications}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(RETRIEVE_APPLICATIONS_PATH),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      yield put(retrieveApplicationsSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveApplicationsFailure(err));
  }
}

export function* retrieveApplicationAPI() {
  while (true) {
    const action = yield take(`${retrieveApplication}`);
    // console.log('node_env', process.env.ENV);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${RETRIEVE_APPLICATION_PATH}${action.payload.id}`),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      // yield call(action.payload.callback, payload || err);
      yield put(retrieveApplicationSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveApplicationsFailure(err));
  }
}

export function* createApplicationAPI() {
  while (true) {
    const action = yield take(`${createApplication}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(CREATE_APPLICATION_PATH),
      action.payload.data,
      "POST",
      {}
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(createApplicationSuccess(payload));
      yield put(retrieveApplications({}));
      continue;
    }
    endSession(err);
    yield put(retrieveApplicationsFailure(err));
  }
}

export function* deleteApplicationAPI() {
  while (true) {
    const action = yield take(`${deleteApplication}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(DELETE_APPLICATION_PATH),
      action.payload.data,
      "POST",
      {}
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(deleteApplicationSuccess(payload));
      yield put(retrieveApplications({}));
      continue;
    }
    endSession(err);
    yield put(retrieveApplicationsFailure(err));
  }
}

export function* updateApplicationAPI() {
  while (true) {
    const action = yield take(`${updateApplication}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${UPDATE_APPLICATION_PATH}${action.payload.id}`),
      action.payload.data,
      "PUT",
      {}
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(updateApplicationSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveApplicationsFailure(err));
  }
}

export function* searchApplicationAPI() {
  while (true) {
    const action = yield take(`${searchApplication}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${SEARCH_APPLICATION_PATH}${action.payload.path}`),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      yield put(searchApplicationSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveApplicationsFailure(err));
  }
}

export function* getApplicationStatsAPI() {
  while (true) {
    yield take(`${getApplicationStats}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(GET_APPLICATION_STATS_PATH),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      yield put(getApplicationStatsSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getApplicationStatsFailure(err));
  }
}

export function* getApplicationTimelineAPI() {
  while (true) {
    const action = yield take(`${getApplicationTimeline}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${GET_APPLICATION_TIMELINE_PATH}${action.payload.id}`),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      yield put(getApplicationTimelineSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getApplicationTimelineFailure(err));
  }
}

export function* getCryptoPropertiesAPI() {
  while (true) {
    const action = yield take(`${getCryptoProperties}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(
        `${CRYPTO_PROPERTIES_PATH}${action.payload.cryptoId}${CRYPTO_PROPERTY}`
      ),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      yield put(getCryptoPropertiesSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getCryptoPropertiesFailure(err));
  }
}

export function* updateApplicationAlgoMapAPI() {
  while (true) {
    const action = yield take(`${updateApplicationAlgoMap}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(APPLICATION_ALGO_CHANGE_PATH),
      action.payload.data,
      "POST",
      {}
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(updateApplicationAlgoMapSuccess(payload));
      // yield put(getCryptoPropertiesSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getCryptoPropertiesFailure(err));
  }
}
