import { take, call, put } from "redux-saga/effects";
import {
  createPolicy,
  createPolicySuccess,
  deletePolicy,
  deletePolicySuccess,
  retrievePolicy,
  retrievePolicies,
  retrievePoliciesFailure,
  retrievePoliciesSuccess,
  retrievePolicySuccess,
  searchPolicy,
  searchPolicySuccess,
  updatePolicy,
  updatePolicySuccess,
  findPoliciesByCryptoId,
  findPoliciesByCryptoIdSuccess,
  updateStatus,
  updateStatusSuccess,
  updateStatusFailure,
} from "../actions/Policy";

import {
  CREATE_POLICY_PATH,
  UPDATE_POLICY_PATH,
  DELETE_POLICY_PATH,
  RETRIEVE_POLICIES_PATH,
  RETRIEVE_POLICY_PATH,
  SEARCH_POLICY_PATH,
  FIND_POLICIES_BY_CRYPTO_ID_PATH,
  UPDATE_STATUS_PATH,
} from "../constants/Policy";

import { endSession } from "../../utils/commonUtils";
import ApiInvoke from "utils/apiFetch";

const backendEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_POLICY_BACKEND_DEV}/${path}`
    : process.env.REACT_APP_ENV === "qa"
    ? `${process.env.REACT_APP_POLICY_BACKEND_QA}/${path}`
    : `${process.env.REACT_APP_POLICY_BACKEND_SANDBOX}/${path}`;

export function* retrievePoliciesAPI() {
  while (true) {
    yield take(`${retrievePolicies}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(RETRIEVE_POLICIES_PATH),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      yield put(retrievePoliciesSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrievePoliciesFailure(err));
  }
}

export function* retrievePolicyAPI() {
  while (true) {
    const action = yield take(`${retrievePolicy}`);
    // console.log('node_env', process.env.ENV);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${RETRIEVE_POLICY_PATH}${action.payload.id}`),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      // yield call(action.payload.callback, payload || err);
      yield put(retrievePolicySuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrievePoliciesFailure(err));
  }
}

export function* createPolicyAPI() {
  while (true) {
    const action = yield take(`${createPolicy}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(CREATE_POLICY_PATH),
      action.payload.data,
      "POST",
      {}
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(createPolicySuccess(payload));
      yield put(retrievePolicies({}));
      continue;
    }
    endSession(err);
    yield put(retrievePoliciesFailure(err));
  }
}

export function* deletePolicyAPI() {
  while (true) {
    const action = yield take(`${deletePolicy}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(DELETE_POLICY_PATH),
      action.payload.data,
      "POST",
      {}
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(deletePolicySuccess(payload));
      yield put(retrievePolicies({}));
      continue;
    }
    endSession(err);
    yield put(retrievePoliciesFailure(err));
  }
}

export function* updatePolicyAPI() {
  while (true) {
    const action = yield take(`${updatePolicy}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${UPDATE_POLICY_PATH}${action.payload.id}`),
      action.payload.data,
      "PUT",
      {}
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(updatePolicySuccess(payload));
      yield put(retrievePolicies({}));
      continue;
    }
    endSession(err);
    yield put(retrievePoliciesFailure(err));
  }
}

export function* searchPolicyAPI() {
  while (true) {
    const action = yield take(`${searchPolicy}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${SEARCH_POLICY_PATH}${action.payload.path}`),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      yield put(searchPolicySuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrievePoliciesFailure(err));
  }
}

export function* findPolicyByCryptoIdAPI() {
  while (true) {
    const action = yield take(`${findPoliciesByCryptoId}`);
    // console.log('node_env', process.env.ENV);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${FIND_POLICIES_BY_CRYPTO_ID_PATH}${action.payload.id}`),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      // yield call(action.payload.callback, payload || err);
      yield put(findPoliciesByCryptoIdSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrievePoliciesFailure(err));
  }
}

export function* updateStatusAPI() {
  while (true) {
    const action = yield take(`${updateStatus}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${UPDATE_STATUS_PATH}${action.payload.id}`),
      action.payload.data,
      "POST",
      {}
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(updateStatusSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(updateStatusFailure(err));
  }
}
