import { take, call, put } from "redux-saga/effects";
import {
  createCrypto,
  createCryptoSuccess,
  deleteCryptoAssets,
  deleteCryptoSuccess,
  downloadCryptoAsset,
  fileUploadInit,
  getCryptoStats,
  getCryptoStatsFailure,
  getCryptoStatsSuccess,
  retrieveCrypto,
  retrieveCryptoAsset,
  retrieveCryptoAssetSuccess,
  retrieveCryptoFailure,
  retrieveCryptoSuccess,
  searchCrypto,
  searchCryptoSuccess,
  updateCrypto,
  updateCryptoSuccess,
  getAuditLogs,
  getAuditLogsSuccess,
  getAuditLogsFailure,
  getCryptoIds,
  getCryptoIdsSuccess,
  getCryptoIdsFailure,
} from "../actions/Crypto";
import {
  CREATE_CRYPTO_ASSET_PATH,
  DELETE_CRYPTO_ASSETS_PATH,
  FILE_UPLOAD_INIT_PATH,
  RETRIEVE_CRYPTO_ASSETS_PATH,
  UPDATE_CRYPTO_ASSET_PATH,
  CRYPTO_BINARY_UPLOAD,
  SEARCH_CRYPTO_ASSET_PATH,
  RETRIEVE_CRYPTO_ASSET_PATH,
  GET_CRYPTO_STATS_PATH,
  AUDIT_LOGS_PATH,
  GET_AUDIT_LOGS_PATH,
  GET_ALL_CRYPTO_IDS_PATH,
} from "../constants/Crypto";
import { DOWNLOAD_ACTION, UPLOAD_ACTION } from "redux/constants/Common";
import fileUpload from "../modules/fileUpload";
import { endSession } from "../../utils/commonUtils";
import ApiInvoke from "utils/apiFetch";

const backendEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_CRYPTO_BACKEND_DEV}/${path}`
    : process.env.REACT_APP_ENV === "qa"
    ? `${process.env.REACT_APP_CRYPTO_BACKEND_QA}/${path}`
    : `${process.env.REACT_APP_CRYPTO_BACKEND_SANDBOX}/${path}`;
const commonEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_COMMON_BACKEND_DEV}/${path}`
    : `${process.env.REACT_APP_COMMON_BACKEND_QA}/${path}`;

const apiKeyCrypto =
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_CRYPTO_BACKEND_DEV_API_KEY}`
    : `${process.env.REACT_APP_CRYPTO_BACKEND_QA_API_KEY}`;

export function* retrieveCryptoAPI() {
  while (true) {
    yield take(`${retrieveCrypto}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(RETRIEVE_CRYPTO_ASSETS_PATH),
      "",
      "GET",
      {
        "x-api-key": apiKeyCrypto,
      }
    );

    if (payload && !err) {
      yield put(retrieveCryptoSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveCryptoFailure(err));
  }
}

export function* retrieveCryptoAssetAPI() {
  while (true) {
    const action = yield take(`${retrieveCryptoAsset}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${RETRIEVE_CRYPTO_ASSET_PATH}${action.payload.id}`),
      "",
      "GET",
      {
        "x-api-key": apiKeyCrypto,
      }
    );

    if (payload && !err) {
      yield put(retrieveCryptoAssetSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveCryptoFailure(err));
  }
}

export function* createCryptoAPI() {
  while (true) {
    const action = yield take(`${createCrypto}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(CREATE_CRYPTO_ASSET_PATH),
      action.payload.data,
      "POST",
      {
        "x-api-key": apiKeyCrypto,
      }
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(createCryptoSuccess(payload));
      yield put(retrieveCrypto({}));
      continue;
    }
    endSession(err);
    yield put(retrieveCryptoFailure(err));
  }
}

export function* updateCryptoAPI() {
  while (true) {
    const action = yield take(`${updateCrypto}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${UPDATE_CRYPTO_ASSET_PATH}${action.payload.id}`),
      action.payload.data,
      "PUT",
      {
        "x-api-key": apiKeyCrypto,
      }
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(updateCryptoSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveCryptoFailure(err));
  }
}

export function* deleteCryptoAPI() {
  while (true) {
    const action = yield take(`${deleteCryptoAssets}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(DELETE_CRYPTO_ASSETS_PATH),
      action.payload.data,
      "POST",
      {
        "x-api-key": apiKeyCrypto,
      }
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(deleteCryptoSuccess(payload));

      if (action.payload.path) {
        yield put(searchCrypto({ path: action.payload.path }));
      } else {
        yield put(retrieveCrypto({}));
      }
      continue;
    }
    endSession(err);
    yield put(deleteCryptoSuccess(err));
  }
}

export function* searchCryptoAPI() {
  while (true) {
    const action = yield take(`${searchCrypto}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${SEARCH_CRYPTO_ASSET_PATH}${action.payload.path}`),
      "",
      "GET",
      {
        "x-api-key": apiKeyCrypto,
      }
    );

    if (payload && !err) {
      yield put(searchCryptoSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveCryptoFailure(err));
  }
}

export function* fileUploadInitAPI() {
  while (true) {
    const action = yield take(`${fileUploadInit}`);

    const { payload } = yield call(
      ApiInvoke,
      commonEndpoint(FILE_UPLOAD_INIT_PATH),
      {
        action: UPLOAD_ACTION,
        type: CRYPTO_BINARY_UPLOAD,
        file: action.payload.data.name,
      },
      "POST",
      {}
    );

    if (payload.status === 200) {
      const uploadURL = payload.data;
      const { res } = yield call(fileUpload, {
        url: uploadURL,
        type: "PUT",
        data: action.payload.data,
      });

      if (res.ok) {
        yield call(action.payload.callback, action.payload);
      }
    }
  }
}

export function* getCryptoStatsAPI() {
  while (true) {
    yield take(`${getCryptoStats}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(GET_CRYPTO_STATS_PATH),
      "",
      "GET",
      {
        "x-api-key": apiKeyCrypto,
      }
    );

    if (payload && !err) {
      yield put(getCryptoStatsSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getCryptoStatsFailure(err));
  }
}

export function* downloadCryptoAssetAPI() {
  while (true) {
    const action = yield take(`${downloadCryptoAsset}`);

    const { payload, err } = yield call(
      ApiInvoke,
      commonEndpoint(FILE_UPLOAD_INIT_PATH),
      {
        action: DOWNLOAD_ACTION,
        type: CRYPTO_BINARY_UPLOAD,
        file: action.payload.file,
      },
      "POST",
      {}
    );

    if (payload) {
      yield call(action.payload.callback, payload);
    }
    endSession(err);
  }
}

export function* getCryptoAuditLogsAPI() {
  while (true) {
    const action = yield take(`${getAuditLogs}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(
        `${AUDIT_LOGS_PATH}${action.payload.id}${GET_AUDIT_LOGS_PATH}`
      ),
      "",
      "GET",
      {
        "x-api-key": apiKeyCrypto,
      }
    );

    if (payload && !err) {
      yield put(getAuditLogsSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getAuditLogsFailure(err));
  }
}

export function* getAllCryptoIds() {
  while (true) {
    yield take(`${getCryptoIds}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(GET_ALL_CRYPTO_IDS_PATH),
      "",
      "GET",
      {
        "x-api-key": apiKeyCrypto,
      }
    );

    if (payload && !err) {
      yield put(getCryptoIdsSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getCryptoIdsFailure(err));
  }
}
